<template>
  <div class="traffic-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>交通路线管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="content-header">
        <el-button type="primary" size="mini" @click="addTraffic">添加交通路线</el-button>
      </div>
      <div class="traffic-list" v-if="dataList.length > 0">
        <div class="traffic-item" v-for="item in dataList" :key="item.id">
          <div class="item title">{{item.title}}</div>
          <div class="item">坐标：{{item.coord}}</div>
          <div class="item">地址：{{item.address}}</div>
          <div class="item-options">
            <div class="edit" @click="editItem(item.id)">编辑</div>
            <div class="del" @click="delItem(item.id)">删除</div>
          </div>
        </div>
      </div>
      <div class="no-list" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取交通路线列表
    async getDataList () {
      const { data: res } = await this.$http.get('/shell-traffic-list', { params: { mId: this.$route.params.mId } })
      if (res.status === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 添加
    addTraffic () {
      this.$router.push({ path: '/admin/apps/shell/content/traffic/' + this.$route.params.mId + '/add', query: { meetingName: this.$route.query.itemName } })
    },
    // 编辑
    editItem (id) {
      this.$router.push({ path: '/admin/apps/shell/content/traffic/' + this.$route.params.mId + '/edit/' + id, query: { meetingName: this.$route.query.itemName } })
    },
    // 删除
    async delItem (id) {
      const { data: res } = await this.$http.delete('/shell-traffic/' + id)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.content-header{
  text-align: right;
}
.traffic-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px;
}
.traffic-list .traffic-item{
  width: 400px;
  height: 140px;
  background-color: rgba(255,255,255,1);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 20px 20px 0;
  background-image: url("../../../../../assets/img/shell/content/traffic-i.png");
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: right;
  padding: 10px;
  text-align: left;
  position: relative;
}
.traffic-list .traffic-item:hover{
  box-shadow: rgb(0 0 0 / 15%) 0px 15px 25px, rgb(0 0 0 / 5%) 0px 5px 10px;
}

.traffic-list .traffic-item .item.title{
  padding: 10px 10px 4px 0px;
  font-size: 13px;
  font-weight: bold;
  color: #303133;
}
.traffic-list .traffic-item .item{
  margin-left: 10px;
  font-size: 13px;
  color: #909399;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  width: calc(100% - 10px);
}
.traffic-list .traffic-item .item-options{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.traffic-list .traffic-item .item-options .edit{
  background-color: #409EFF;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 26px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}
.traffic-list .traffic-item .item-options .del{
  background-color: #F56C6C;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 26px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}
</style>
